const tools = {
  parseRichTextImages(thisRef) {
    const attachments = thisRef.$el.querySelectorAll('action-text-attachment')

    for (const attachment of attachments) {
      const img = document.createElement('img')
      img.setAttribute('src', attachment.getAttribute('url'))
      img.setAttribute('sgid', attachment.getAttribute('sgid'))
      img.setAttribute('class', "action-text-image")
      attachment.parentNode.replaceChild(img, attachment)
    }
  }
}

export default tools;
