// Theme Variables
import "../src/variables.scss"

// Import vue
import Vue from 'vue'

// Custom Resource handler for Rails using Axios
import HttpPlugin from './http-plugin'
Vue.use(HttpPlugin)

// Various function tools
import tools from './tools'
Vue.prototype.$tools = tools

// Vuetify
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.css'
Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    primary: '#5aadff',
    success: '#00995f',
    error: '#AC0000',
    action: '#ec9b00'
  }
})

// Add v-visible support
import VueVisible from 'vue-visible';
Vue.use(VueVisible);

// Add cookie support
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.$cookies.config(-1, null, null, null, "Lax"); // expireTimes, path , domain, secure, sameSite

// Vue moment.js
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
Vue.use(VueMoment, { moment })

// Nprogress
import NProgressPlugin from '@plugins/nprogress-plugin'
Vue.use(NProgressPlugin)

// Global event bus
import GlobalEventPlugin from '@plugins/eventBus'
Vue.use(GlobalEventPlugin)

// infinite scroll
import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo, {
  container: ".v-navigation-drawer",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

// import styles - always last
import "../src/application.scss"
